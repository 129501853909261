
export const environment = {
  production: "true",
  gs1BaseUrl: "https://www.gs1.org/",
  gs1PPUrl: "",
  gs1VBGUrl: "https://grp.gs1.org/vbg-st/v1",
  gs1GRPUrl: "https://grp.gs1.org/vbg-st/spa/v3",
  ssoAuthority: "https://gs1sso-dev.gs1.org",
  ssoClientId: "GDOSCUISTAGE",
  ssoSilentRefreshUri: "https://gdosc.gs1.org/assets/silent-refresh.html",
  ssoRedirectUri: "https://gdosc-st.gs1.org/auth-callback",
  ssoPostLogoutRedirectUri: "https://gdosc-st.gs1.org",
  ssoResponseType: "code",
  ssoScope: "openid profile azure-apim-sso-jwt",
  exportFileName: "exportresult.json",
  gaTrackingId: "G-2Y83B4WT7S",
  checkdigit_Calculator_Url: "https://www.gs1.org/services/check-digit-calculator",
  cdnUrl: "https://endpoint-euw-st-gdosc-01-etczd5aydadkf0fg.z01.azurefd.net",
};